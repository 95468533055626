import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Divider, Form, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import styled from 'styled-components';
import { DatePickerTKG, InputNumberTKG, InputTKG, ModalFormTKG, PopupStatusTKG, RangePickerTKG, SelectTKG, TextEditorTKG } from 'tkg-composite-ui';
import { listAll as ListClaimAll } from '../../api/service/claimService';
import { listAll } from '../../api/service/fineService';
import { create } from '../../api/service/paymentsService';
import { listUser } from '../../api/service/userService';
import { format_money, typeOverTime } from '../../assets/constants';
import fetchError from '../../components/functions/fetchError';

const { Title } = Typography;

const CardContent = styled.div`
    background: #fff;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%);
    border-radius: 10px;
    margin-top: 0.2em;
    padding: 1em;
`;

const CardFields = styled.div`
    background: rgb(240 240 240 / 49%);
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%);
    border-radius: 10px;
    margin-top: 0.2em;
    padding: 1em;
`;

const Create = ({ handleCallBack }) => {
    const [listUserId, setListUserId] = useState([]);
    const [finesAll, setFinesAll] = useState([]);
    const [claimAll, setClaimAll] = useState([]);
    const [userSelect, setUserSelect] = useState({});
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [dataOverTime, setDataOverTime] = useState([]);
    const [validateStatus, setValidateStatus] = useState('');
    const [help, setHelp] = useState('');
    const [checkBox, setCheckBox] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [daylyRate, setDayLyRate] = useState('');
    const [hourlyRate, setHourLyRate] = useState('');
    const [minuteRate, setMinuteRate] = useState('');
    const showCode = () => {
        const getListUser = async () => {
            return await listUser();
        };
        getListUser().then((response) => {
            if (response?.data?.meta?.status === 200) {
                const dataUser = response?.data?.response.map((e) => {
                    return {
                        value: e?.id,
                        label: e?.first_name + ' ' + e?.surname,
                        ...e,
                    };
                });

                setListUserId(dataUser);
            }
        });
        setOpenDrawer(true);
    };
    const onClose = () => {
        setOpenDrawer(false);
    };

    const onFinish = async (values) => {
        const data = {
            ...values,
            type: 1,
            payment_date: values?.payment_date ? values?.payment_date.format('YYYY-MM-DD') : null,
            month: values?.month ? values?.month.format('YYYY-MM') : null,
            overtime: values.overtime
                ? values.overtime.map((OT) => {
                    return {
                        ...OT,
                        date: OT?.date?.format('YYYY-MM-DD'),
                    };
                })
                : null,
            other: values.other
                ? values.other.map((OTh) => {
                    return {
                        ...OTh,
                        date: OTh?.date?.format('YYYY-MM-DD'),
                    };
                })
                : null,
            other_less: values.other_less
                ? values.other_less.map((OThL) => {
                    return {
                        ...OThL,
                        date: OThL?.date?.format('YYYY-MM-DD'),
                    };
                })
                : null,
            tardiness_leave_early: values.tardiness_leave_early
                ? values.tardiness_leave_early.map((OThTLE) => {
                    return {
                        ...OThTLE,
                        date: OThTLE?.date?.format('YYYY-MM-DD'),
                    };
                })
                : null,
        };
        setLoading(true);
        const response = await create(data);
        if (response?.data?.meta?.status === 200) {
            form.resetFields();
            setLoading(false);
            onClose();
            handleCallBack();
            PopupStatusTKG('success', 'Create successfully');
        } else {
            setLoading(false);
            PopupStatusTKG('error', fetchError(response));
        }
    };

    const handleChangeType = (e, key, index) => {
        const data = form.getFieldValue('overtime');
        let overtime_money = 0;
        let typeLabel = '';
        if (e === 'normal') {
            overtime_money = userSelect?.overtime_normal;
            typeLabel = 'Type Normal - ' + format_money(overtime_money) + ' - ' + userSelect.currency;
        } else if (e === 'weekend') {
            overtime_money = userSelect?.overtime_weekend;
            typeLabel = 'Type Weekend - ' + format_money(overtime_money) + ' - ' + userSelect.currency;
        } else if (e === 'holiday') {
            overtime_money = userSelect?.overtime_holiday;
            typeLabel = 'Type Holiday - ' + format_money(overtime_money) + ' - ' + userSelect.currency;
        } else {
            typeLabel = 'Type';
        }
        if (data[index]?.hours) {
            data[index] = { ...data[index], typeLabel, hours_amount: data[index]?.hours * overtime_money };
        } else {
            data[index] = { ...data[index], typeLabel, hours_amount: 0 };
        }
        setDataOverTime(data);
        form.setFieldValue('overtime', data);
    };

    const onChangeHours = (e, key, name, index) => {
        const data = form.getFieldValue('overtime');
        let overtime_money = 0;
        let typeLabel = '';
        if (data[index]?.type) {
            if (data[index].type === 'normal') {
                overtime_money = userSelect?.overtime_normal;
                typeLabel = 'Type Normal - ' + format_money(overtime_money) + ' - ' + userSelect.currency;
            } else if (data[index].type === 'weekend') {
                overtime_money = userSelect?.overtime_weekend;
                typeLabel = 'Type Weekend - ' + format_money(overtime_money) + ' - ' + userSelect.currency;
            } else if (data[index].type === 'holiday') {
                overtime_money = userSelect?.overtime_holiday;
                typeLabel = 'Type Holiday - ' + format_money(overtime_money) + ' - ' + userSelect.currency;
            } else {
                typeLabel = 'Type';
            }
            data[index] = { ...data[index], typeLabel, hours_amount: data[index]?.hours ? data[index]?.hours * overtime_money : 0 };
            setDataOverTime(data);
            form.setFieldValue('overtime', data);
        }
    };

    async function fetchAllFine(e) {
        await listAll(e).then((response) => {
            if (response?.data?.meta?.status === 200) {
                setFinesAll(
                    response?.data?.response.map((e, index) => {
                        return {
                            value: e?.id,
                            label: e?.title,
                            key: index,
                            ...e,
                            action: e,
                        };
                    }),
                );
            }
        });
    }

    async function fetchAllClaim(e) {
        await ListClaimAll(e).then((response) => {
            if (response?.data?.meta?.status === 200) {
                setClaimAll(
                    response?.data?.response.map((e, index) => {
                        return {
                            value: e?.id,
                            label: e?.title,
                            key: index,
                            ...e,
                            action: e,
                        };
                    }),
                );
            }
        });
    }

    const handleChangeClaim = (e, key, index) => {
        const data = form.getFieldValue('claim');
        const claimSelect = claimAll.find((res) => res?.id === e);
        if (claimSelect) {
            data[index] = { ...data[index], id: e, title: claimSelect?.title, amount: claimSelect?.amount };
            // , date: dayjs(claimSelect?.date_claims).format('DD-MMM-YYYY') }
        }
        setClaimAll(
            claimAll.map((rec, ind) => {
                return {
                    value: rec?.id,
                    label: rec?.title,
                    key: ind,
                    ...rec,
                    action: rec,
                    disabled: data.find((fil) => {
                        return fil?.id === rec?.id;
                    }),
                };
            }),
        );
        form.setFieldValue('claim', data);
    };

    const handleChangeFines = (e, key, index) => {
        const data = form.getFieldValue('fine');
        const fineSelect = finesAll.find((res) => res?.id === e);
        if (fineSelect) {
            data[index] = { ...data[index], id: e, title: fineSelect?.title, amount: fineSelect?.amount };
            // , date: dayjs(fineSelect?.date_fines).format('DD-MMM-YYYY') };
        }
        setFinesAll(
            finesAll.map((rec, ind) => {
                return {
                    value: rec?.id,
                    label: rec?.title,
                    key: ind,
                    ...rec,
                    action: rec,
                    disabled: data.find((fil) => {
                        return fil?.id === rec?.id;
                    }),
                };
            }),
        );
        form.setFieldValue('fine', data);
    };

    const handleGetToltalAmount = async (e) => {
        form.resetFields(['kpi_bonus', 'overtime', 'less', 'fine', 'claim', 'other', 'other_less', 'tardiness_leave_early']);
        setFinesAll([]);
        setClaimAll([]);
        form.setFieldsValue({ currency: listUserId.find((id) => id.value === e)?.currency, amount: listUserId.find((id) => id.value === e)?.yearly_salary });
        setValidateStatus('');
        setHelp('');
        fetchAllFine(e);
        fetchAllClaim(e);
        setUserSelect(listUserId.filter((id) => id.value === e)[0]);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const scollToUser = (icon, message) => {
        document.getElementById('refScroll').focus();
        setValidateStatus('error');
        setHelp('Please select User');
        PopupStatusTKG(icon, message);
    };

    const onChange = (e) => {
        setCheckBox(e.target.checked);
    };
    const onChangeWorkDays = (e) => {
        const yearly_salary = form.getFieldValue('amount');
        setDayLyRate(`${e.target.value > 0 ? format_money(Math.round(yearly_salary / e.target.value), 0) : ''}`);
        setHourLyRate(`${e.target.value > 0 ? format_money(Math.round(yearly_salary / e.target.value / 8), 0) : ''}`);
        setMinuteRate(`${e.target.value > 0 ? format_money(Math.round(yearly_salary / e.target.value / (8 * 60)), 0) : ''}`);
    };

    const handleChangeMonth = (e) => {
        const startOfMonth = dayjs(e.format('YYYY-MM')).startOf('month');
        const endOfMonth = dayjs(e.format('YYYY-MM')).endOf('month');
        return form.setFieldsValue({ work_period: [startOfMonth, endOfMonth] });
    };

    return (
        <>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => showCode()}>
                Create Payment
            </Button>
            <ModalFormTKG title="Create Payment" closable={false} onClose={onClose} open={openDrawer}>
                <CardContent>
                    <Form
                        id="basic"
                        name="basic"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        autoComplete="off"
                        layout="vertical"
                        form={form}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        scrollToFirstError={true}
                    >
                        <Row gutter={[16, 16]}>
                            <Col span={6}>
                                <Form.Item form={form} label="Type">
                                    <InputTKG value={'Wages'} readOnly />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="user_id"
                                    label="User"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select User',
                                        },
                                    ]}
                                    validateStatus={validateStatus}
                                    help={help}
                                >
                                    <SelectTKG
                                        id="refScroll"
                                        form={form}
                                        showSearch
                                        options={listUserId}
                                        onChange={handleGetToltalAmount}
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="title"
                                    label="Title"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input Title',
                                        },
                                    ]}
                                >
                                    <InputTKG form={form} placeholder="Please input Title" />
                                </Form.Item>
                            </Col>
                            <Col span={16}>
                                <Row gutter={[12, 12]}>
                                    <Col span={12}>
                                        <Form.Item
                                            form={form}
                                            label="Work Period"
                                            name="work_period"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select Work Period',
                                                },
                                            ]}
                                        >
                                            <RangePickerTKG disabled={checkBox ? true : false} />
                                        </Form.Item>
                                        {checkBox ? (
                                            <Form.Item form={form} label="Month" name="month">
                                                <DatePickerTKG picker="month" format="MMM-YYYY" onChange={(e) => handleChangeMonth(e)} />
                                            </Form.Item>
                                        ) : (
                                            <></>
                                        )}
                                        <Checkbox onChange={onChange} checked={checkBox}>
                                            Monthly salary (used for Vietnam)
                                        </Checkbox>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            form={form}
                                            label="Payment Date"
                                            name="payment_date"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select Payment Date',
                                                },
                                            ]}
                                        >
                                            <DatePickerTKG />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="amount" label="Amount">
                                            <InputNumberTKG formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} form={form} readOnly />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="currency" label="Currency">
                                            <InputTKG readOnly form={form} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={8}>
                                <Row gutter={12}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="work_days"
                                            label="Work Days"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input work days',
                                                },
                                            ]}
                                        >
                                            <InputTKG form={form} onChange={onChangeWorkDays} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Tool convert salary rate: ">
                                            <p style={{ marginLeft: '20px' }}>Daily: {daylyRate}</p>
                                            <p style={{ marginLeft: '20px' }}>Hourly: {hourlyRate}</p>
                                            <p style={{ marginLeft: '20px' }}>Minute: {minuteRate}</p>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <CardFields>
                                    <Divider orientation="left" orientationMargin="0">
                                        <Title level={4} style={{ margin: 'auto' }}>
                                            Add More Payments
                                        </Title>{' '}
                                    </Divider>
                                    <Col span={24}>
                                        <Title level={5} className="title-Form-payment">
                                            KPI Bonus
                                        </Title>

                                        <Form.List name="kpi_bonus">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map((field, index) => (
                                                        <Row gutter={16} key={index}>
                                                            <Col span={11}>
                                                                <Form.Item
                                                                    label="Title"
                                                                    name={[field.name, 'title']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Missing title',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <InputTKG placeholder="Title" form={form} />
                                                                </Form.Item>
                                                            </Col>
                                                            {/* <Col span={6}>
                                                                        <Form.Item
                                                                            label="Date"
                                                                            name={[field.name, 'date']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Missing date',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <DatePickerTKG form={form} />
                                                                        </Form.Item>
                                                                    </Col> */}
                                                            <Col span={11}>
                                                                <Form.Item
                                                                    label="Amount"
                                                                    name={[field.name, 'amount']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Missing amount',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <InputNumberTKG
                                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                        placeholder="Amount"
                                                                        form={form}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            {/* <Col span={4}>
                                                                        <Form.Item
                                                                            label="Curency"
                                                                            name={[field.name, 'currency']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Missing currency',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <SelectTKG options={currencyAll} placeholder="Currency" />
                                                                        </Form.Item>
                                                                    </Col> */}
                                                            <Col span={2}>
                                                                <MinusCircleOutlined className="minus-icon" onClick={() => remove(field.name)} />
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => {
                                                            Object.keys(userSelect).length !== 0 ? add() : scollToUser('warning', 'Please select User First!');
                                                        }}
                                                        block
                                                        icon={<PlusOutlined />}
                                                    >
                                                        Add field KPI Bonus
                                                    </Button>
                                                </>
                                            )}
                                        </Form.List>
                                    </Col>
                                    <Col span={24}>
                                        <Title level={5} className="title-Form-payment">
                                            Overtime
                                        </Title>

                                        <Form.List name="overtime">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map((field, index) => (
                                                        <Row gutter={16} key={index}>
                                                            <Col span={23}>
                                                                <Row gutter={16}>
                                                                    <Col span={12} md={12} lg={6}>
                                                                        <Form.Item
                                                                            label="Title"
                                                                            name={[field.name, 'title']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Missing title',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <InputTKG placeholder="Title" form={form} />
                                                                        </Form.Item>
                                                                    </Col>

                                                                    <Col span={12} md={12} lg={4}>
                                                                        <Form.Item
                                                                            label={
                                                                                dataOverTime && dataOverTime[index]?.typeLabel
                                                                                    ? dataOverTime[index]?.typeLabel
                                                                                    : 'Type'
                                                                            }
                                                                            name={[field.name, 'type']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Missing type',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <SelectTKG
                                                                                options={typeOverTime}
                                                                                onChange={(e) => handleChangeType(e, field.key, index)}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={8} md={8} lg={4}>
                                                                        <Form.Item
                                                                            label="Hours"
                                                                            name={[field.name, 'hours']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Missing hours',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <InputNumberTKG
                                                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                placeholder="Hours"
                                                                                form={form}
                                                                                onChange={(e) => onChangeHours(e, field.key, field.name, index)}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={8} md={8} lg={4}>
                                                                        <Form.Item
                                                                            label="Date"
                                                                            name={[field.name, 'date']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Missing date',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <DatePickerTKG form={form} />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={8} md={8} lg={4}>
                                                                        <Form.Item label="Result" form={form} name={[field.name, 'hours_amount']}>
                                                                            <InputNumberTKG
                                                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                placeholder="result"
                                                                                readOnly
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col span={1} style={{ alignSelf: 'center' }}>
                                                                <MinusCircleOutlined className="minus-icon" onClick={() => remove(field.name)} />
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => {
                                                            Object.keys(userSelect).length !== 0 ? add() : scollToUser('warning', 'Please select User First!');
                                                        }}
                                                        block
                                                        icon={<PlusOutlined />}
                                                    >
                                                        Add field Overtime
                                                    </Button>
                                                </>
                                            )}
                                        </Form.List>
                                    </Col>
                                    {claimAll.length > 0 ? (
                                        <Col span={24}>
                                            <Title level={5} className="title-Form-payment">
                                                Claim
                                            </Title>

                                            <Form.List name="claim">
                                                {(fields, { add, remove }) => (
                                                    <>
                                                        {fields.map((field, index) => (
                                                            <Row gutter={16} key={index}>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        label="Title"
                                                                        name={[field.name, 'title']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Missing claim',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <SelectTKG
                                                                            showSearch
                                                                            options={claimAll}
                                                                            form={form}
                                                                            onChange={(e) => handleChangeClaim(e, field.key, index)}
                                                                            filterOption={(input, option) =>
                                                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                {/* <Col span={6}>
                                                                    <Form.Item
                                                                        label="Date"
                                                                        name={[field.name, 'date']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Missing date',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <InputTKG form={form} readOnly />
                                                                    </Form.Item>
                                                                </Col> */}
                                                                <Col span={10}>
                                                                    <Form.Item
                                                                        label="Amount"
                                                                        name={[field.name, 'amount']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Missing amount',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <InputNumberTKG
                                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                            placeholder="Amount"
                                                                            form={form}
                                                                            readOnly
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={2}>
                                                                    <MinusCircleOutlined
                                                                        className="minus-icon"
                                                                        onClick={() => {
                                                                            remove(field.name);
                                                                            handleChangeClaim();
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        ))}
                                                        <Button
                                                            type="dashed"
                                                            onClick={() => {
                                                                Object.keys(userSelect).length !== 0
                                                                    ? claimAll.length > 0
                                                                        ? fields.length < claimAll.length
                                                                            ? add()
                                                                            : PopupStatusTKG(
                                                                                'warning',
                                                                                'You have selected all claims. Please create more claims to add the field!',
                                                                            )
                                                                        : PopupStatusTKG('warning', 'Please Create Claim Before Add!')
                                                                    : scollToUser('warning', 'Please Select User First!');
                                                            }}
                                                            block
                                                            icon={<PlusOutlined />}
                                                        >
                                                            Add field Claim
                                                        </Button>
                                                    </>
                                                )}
                                            </Form.List>
                                        </Col>
                                    ) : (
                                        <></>
                                    )}
                                    <Col span={24}>
                                        <Title level={5} className="title-Form-payment">
                                            Other
                                        </Title>

                                        <Form.List name="other">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }) => (
                                                        <Row gutter={16} key={key}>
                                                            <Col span={22}>
                                                                <Row gutter={16}>
                                                                    <Col span={12} md={12} lg={6}>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            label="Title"
                                                                            name={[name, 'title']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Missing other',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <InputTKG placeholder="Title" form={form} />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={12} md={12} lg={6}>
                                                                        <Form.Item label="Date" name={[name, 'date']}>
                                                                            <DatePickerTKG form={form} />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={12} md={12} lg={6}>
                                                                        <Form.Item {...restField} label="Hours" name={[name, 'hours']}>
                                                                            <InputNumberTKG
                                                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                placeholder="Hours"
                                                                                form={form}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={12} md={12} lg={6}>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            label="Amount"
                                                                            name={[name, 'amount']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Missing amount',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <InputNumberTKG
                                                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                placeholder="Amount"
                                                                                form={form}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col span={2}>
                                                                <MinusCircleOutlined className="minus-icon" onClick={() => remove(name)} />
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => {
                                                            Object.keys(userSelect).length !== 0 ? add() : scollToUser('warning', 'Please select User First!');
                                                        }}
                                                        block
                                                        icon={<PlusOutlined />}
                                                    >
                                                        Add field Other
                                                    </Button>
                                                </>
                                            )}
                                        </Form.List>
                                    </Col>
                                </CardFields>
                            </Col>

                            <Col span={24}>
                                <CardFields>
                                    <Divider orientation="left" orientationMargin="0">
                                        <Title level={4} style={{ margin: 'auto' }}>
                                            Less
                                        </Title>{' '}
                                    </Divider>
                                    {finesAll.length > 0 ? (
                                        <Col span={24}>
                                            <Title level={5} className="title-Form-payment">
                                                Fines
                                            </Title>

                                            <Form.List name="fine">
                                                {(fields, { add, remove }) => (
                                                    <>
                                                        {fields.map((field, index) => (
                                                            <Row gutter={16} key={index}>
                                                                <Col span={11}>
                                                                    <Form.Item
                                                                        label="Title"
                                                                        name={[field.name, 'title']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Missing fine',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <SelectTKG
                                                                            showSearch
                                                                            options={finesAll}
                                                                            form={form}
                                                                            onChange={(e) => handleChangeFines(e, field.key, index)}
                                                                            filterOption={(input, option) =>
                                                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                {/* <Col span={7}>
                                                                    <Form.Item
                                                                        label="Date"
                                                                        name={[field.name, 'date']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Missing date',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <InputTKG form={form} readOnly />
                                                                    </Form.Item>
                                                                </Col> */}
                                                                <Col span={11}>
                                                                    <Form.Item
                                                                        label="Amount"
                                                                        name={[field.name, 'amount']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Missing amount',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <InputNumberTKG
                                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                            placeholder="Amount"
                                                                            form={form}
                                                                            readOnly
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={2}>
                                                                    <MinusCircleOutlined
                                                                        className="minus-icon"
                                                                        onClick={() => {
                                                                            remove(field.name);
                                                                            handleChangeFines();
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        ))}
                                                        <Button
                                                            type="dashed"
                                                            onClick={() => {
                                                                Object.keys(userSelect).length !== 0
                                                                    ? finesAll.length > 0
                                                                        ? fields.length < finesAll.length
                                                                            ? add()
                                                                            : PopupStatusTKG(
                                                                                'warning',
                                                                                'You have selected all fines. Please create more fines to add the field!',
                                                                            )
                                                                        : PopupStatusTKG('warning', 'Please Create Fines Before Add!')
                                                                    : scollToUser('warning', 'Please Select User First!');
                                                            }}
                                                            block
                                                            icon={<PlusOutlined />}
                                                        >
                                                            Add field Fines
                                                        </Button>
                                                    </>
                                                )}
                                            </Form.List>
                                        </Col>
                                    ) : (
                                        <></>
                                    )}
                                    <Col span={24}>
                                        <Title level={5} className="title-Form-payment">
                                            Tax
                                        </Title>

                                        <Form.List name="less">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }) => (
                                                        <Row gutter={16} key={key}>
                                                            <Col span={11}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Title"
                                                                    name={[name, 'title']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Missing less',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <InputTKG placeholder="Title" form={form} />
                                                                </Form.Item>
                                                            </Col>
                                                            {/* <Col span={7}>
                                                                        <Form.Item
                                                                            label="Date"
                                                                            name={[name, 'date']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Missing date',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <DatePickerTKG form={form} picker="month" format="MMM-YYYY" />
                                                                        </Form.Item>
                                                                    </Col> */}
                                                            <Col span={11}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Amount"
                                                                    name={[name, 'amount']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Missing amount',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <InputNumberTKG
                                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                        placeholder="Amount"
                                                                        form={form}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={2}>
                                                                <MinusCircleOutlined className="minus-icon" onClick={() => remove(name)} />
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => {
                                                            Object.keys(userSelect).length !== 0 ? add() : scollToUser('warning', 'Please select User First!');
                                                        }}
                                                        block
                                                        icon={<PlusOutlined />}
                                                    >
                                                        Add field Tax
                                                    </Button>
                                                </>
                                            )}
                                        </Form.List>
                                    </Col>
                                    <Col span={24}>
                                        <Title level={5} className="title-Form-payment">
                                            Other
                                        </Title>

                                        <Form.List name="other_less">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }) => (
                                                        <Row gutter={16} key={key}>
                                                            <Col span={22}>
                                                                <Row gutter={16}>
                                                                    <Col span={12} md={12} lg={6}>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            label="Title"
                                                                            name={[name, 'title']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Missing other',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <InputTKG placeholder="Title" form={form} />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={12} md={12} lg={6}>
                                                                        <Form.Item label="Date" name={[name, 'date']}>
                                                                            <DatePickerTKG form={form} />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={12} md={12} lg={6}>
                                                                        <Form.Item {...restField} label="Hours" name={[name, 'hours']}>
                                                                            <InputNumberTKG
                                                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                placeholder="Hours"
                                                                                form={form}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={12} md={12} lg={6}>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            label="Amount"
                                                                            name={[name, 'amount']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Missing amount',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <InputNumberTKG
                                                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                placeholder="Amount"
                                                                                form={form}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col span={2}>
                                                                <MinusCircleOutlined className="minus-icon" onClick={() => remove(name)} />
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => {
                                                            Object.keys(userSelect).length !== 0 ? add() : scollToUser('warning', 'Please select User First!');
                                                        }}
                                                        block
                                                        icon={<PlusOutlined />}
                                                    >
                                                        Add field Other
                                                    </Button>
                                                </>
                                            )}
                                        </Form.List>
                                    </Col>
                                    <Col span={24}>
                                        <Title level={5} className="title-Form-payment">
                                            Tardiness/Leave Early
                                        </Title>

                                        <Form.List name="tardiness_leave_early">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }) => (
                                                        <Row gutter={16} key={key}>
                                                            <Col span={22}>
                                                                <Row gutter={16}>
                                                                    <Col span={12} md={12} lg={12}>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            label="Title"
                                                                            name={[name, 'title']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Missing other',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <InputTKG placeholder="Title" form={form} />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={12} md={12} lg={12}>
                                                                        <Form.Item label="Date" name={[name, 'date']}>
                                                                            <DatePickerTKG form={form} />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={12} md={12} lg={8}>
                                                                        <Form.Item {...restField} label="Hours" name={[name, 'hours']}>
                                                                            <InputNumberTKG
                                                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                placeholder="Hours"
                                                                                form={form}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={12} md={12} lg={8}>
                                                                        <Form.Item {...restField} label="Minutes" name={[name, 'minutes']}>
                                                                            <InputNumberTKG
                                                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                placeholder="Minutes"
                                                                                form={form}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={12} md={12} lg={8}>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            label="Amount"
                                                                            name={[name, 'amount']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Missing amount',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <InputNumberTKG
                                                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                placeholder="Amount"
                                                                                form={form}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col span={2}>
                                                                <MinusCircleOutlined className="minus-icon" onClick={() => remove(name)} />
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => {
                                                            Object.keys(userSelect).length !== 0 ? add() : scollToUser('warning', 'Please select User First!');
                                                        }}
                                                        block
                                                        icon={<PlusOutlined />}
                                                    >
                                                        Add field Tardiness/Leave Early
                                                    </Button>
                                                </>
                                            )}
                                        </Form.List>
                                    </Col>
                                </CardFields>
                            </Col>

                            <Col span={24}>
                                <Form.Item form={form} name="note" label="Note">
                                    <TextEditorTKG placeholder="Content here" form={form} />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Button type="primary" className="margin-08" htmlType="submit" loading={loading}>
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </CardContent>
            </ModalFormTKG>
        </>
    );
};

export default Create;
